import { Dialog, DialogActions, DialogContent, DialogTitle, Pagination } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { BiFilter, BiSearch } from "react-icons/bi";
import { IoMdAdd } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Loder from "../../Loder/Loder";
import { notificationHandler } from "../../utils/Notification";
import ImagePreview from "../Banner/ImagePreview";
import s from "../Banner/banner.module.css";
import DataNotFound from "../ErrorPage/DataNotFound";
import { deleteBanner_api, getBanners_api, statusUpdate_banner_api } from "../api/banner";

const PujaBanners = () => {
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [allbanner, setallbanner] = useState([]);
  const [pageCount, setpageCount] = useState(parseInt(localStorage?.getItem("bannerIndex")) || 1);
  const [deleteId, setdeleteId] = useState("");
  const [deletePopup, setdeletePopup] = useState(false);
  const [currentGroup, setcurrentGroup] = useState({});
  const [bannerImagePreview, setBannerImagePreview] = useState("");
  const [pageLength, setpageLength] = useState();
  const [search, setsearch] = useState("");

  useEffect(() => {
    fetchallbannerFunc();
  }, [pageCount, search]);

  async function fetchallbannerFunc() {
    setisLoading(true);
    try {
      const temp = {
        page: pageCount, 
        limit: 10,
        search: search.trim(),
        bannerType: "mandir_puja"
      };
      let res = await getBanners_api(temp);
      if (res.data.status) {
        setallbanner(res?.data?.data?.banners);
        setpageLength(res?.data?.data?.totalPages);
        setisLoading(false);
      } else {
        setisLoading(false);
        console.log("status false!");
      }
    } catch (error) {
      console.log(error);
    }
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "var(--clr-theme)",
      color: theme.palette.common.white,
      fontWeight: "bold",
      borderRight: "1px solid #fff",
      overflow: "hidden",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: "none",
      borderLeft: "2px solid #00000011",
      "&:last-child": {
        borderRight: "2px solid #00000011",
      },
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    borderBottom: "2px solid #00000011",
  }));


  async function deleteuserFunc() {
    setisLoading(true);
    let temp = {
      id: deleteId,
    };
    try {
      let res = await deleteBanner_api(temp);
      console.log(res);
      if (res.data.status) {
        setisLoading(false);
        setdeletePopup(false);
        fetchallbannerFunc();
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        setisLoading(false);
        notificationHandler({ type: "danger", msg: res.data.message });
      }
    } catch (error) {
      console.log(error);
      notificationHandler({ type: "danger", msg: error.message });
    }
  }

  const banner_status = async (data) => {
    setisLoading(true);
    const fd = new FormData();
    fd.append("id", data?.id);
    fd.append("priority", data?.priority);
    fd.append("title", data?.title)
    data?.slug && fd.append("slug", data?.slug)
    fd.append("startDate", data?.start_date);
    fd.append("endDate", data?.end_date);
    fd.append("bannerType", "mandir_puja")   
    fd.append("status", !data?.status);
    try {
      let res = await statusUpdate_banner_api(fd);
      console.log(res);
      if (res.data.status) {
        setisLoading(false);
        fetchallbannerFunc();
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        setisLoading(false);
        notificationHandler({ type: "danger", msg: res.data.message });
      }
    } catch (error) {
      console.log(error);
      notificationHandler({ type: "danger", msg: error.message });
    }
  };


  return (
    <div className="">
      <div className="beat_heading">
        <div className={s["user-list-heading"]}>
          <div className="user-list-title">
            <h3>Puja Banners</h3>
          </div>
          <div className={s["user-list-search"]}>
            <div className={s["search-box"]}>
              <span style={{ paddingRight: "0.5rem" }}>
                <BiSearch size={23} />
              </span>
              <input
                type="text"
                spellCheck="false"
                onChange={(e) => {
                  setpageCount(1);
                  setsearch(e.target.value);
                }}
                placeholder="Search by title..."
              />
            </div>
            <div className={s["filter-btn"]}>
              <span style={{ paddingRight: "2px" }}>
                <BiFilter size={20} />
              </span>
              Filter
            </div>
          </div>
        </div>
        <div className="beat_left">
          <div
            className={s["title"]}
            onClick={() =>
              navigate("/add-puja-banner", {
                state: {
                  pagetype: "Add",
                },
              })
            }
          >
            <IoMdAdd /> Puja Banner
          </div>
        </div>
      </div>
      <div className="beat_table">
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Image</StyledTableCell>
              <StyledTableCell align="center">ID</StyledTableCell>
              <StyledTableCell align="center">Title</StyledTableCell>
              <StyledTableCell align="center">Start Date</StyledTableCell>
              <StyledTableCell align="center">End Date</StyledTableCell>
              <StyledTableCell align="center">Slug</StyledTableCell>
              <StyledTableCell align="center">Priority</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {allbanner?.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell >{row.banner_image ?
                  <img style={{ height: "2rem", width: "3rem" }} className={s["ImageBannerList"]} src={row.banner_image} onMouseOver={()=>setBannerImagePreview(row.banner_image)} onMouseLeave={()=>setBannerImagePreview("")} alt="" />
                  : null}</StyledTableCell>
                <StyledTableCell align="center">{row.id}</StyledTableCell>
                <StyledTableCell align="center">{row.title}</StyledTableCell>
                <StyledTableCell align="center">{
                  new Date(row.start_date).toLocaleDateString("en-IN", {
                    year: "numeric",
                    month: "numeric",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                  })
                }</StyledTableCell>
                <StyledTableCell align="center">{
                  new Date(row.end_date).toLocaleDateString("en-IN", {
                    year: "numeric",
                    month: "numeric",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                  })
                }</StyledTableCell>
                <StyledTableCell align="center">{row.slug}</StyledTableCell>
                <StyledTableCell align="center">{row.priority}</StyledTableCell>

                <StyledTableCell align="center">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => banner_status(row)}
                    className={`${row.status === true ? s.active_admin : s.inactive_admin}`}
                  >
                    {row.status === true ? "Active" : "Inactive"}
                  </div>
                </StyledTableCell>

                <StyledTableCell align="center">
                  <div className={s["astrologer-action-btn"]}>
                    <span
                      onClick={() =>
                        navigate("/add-puja-banner", {
                          state: {
                            pagetype: "Edit",
                            data: row,
                          },
                        })
                      }
                    >
                      Edit
                    </span>
                    <span
                      onClick={() => {
                        setdeletePopup(true);
                        setcurrentGroup(row);
                        setdeleteId(row.id);
                      }}
                    >
                      Delete
                    </span>
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        {allbanner?.length <= 0 && <DataNotFound />}
        {allbanner?.length > 0 && (
          <div className={s["pagination"]}>
            <Pagination count={pageLength} size="large" style={{ color: "#D21903" }} onChange={(e, value) => {setpageCount(value); localStorage.setItem("bannerIndex",value)}} page={pageCount} />
          </div>
        )}
      </div>

      <Dialog open={deletePopup} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth="true" onClose={() => setdeletePopup(false)}>
        <DialogTitle className={s.dialog_title}>
          <div>Do you want to delete {currentGroup.category_name}?</div>
        </DialogTitle>
        <DialogContent className={s.cardpopup_content}>
          <div style={{ display: "flex", gap: "1rem" }}>
            <div className={s.employee_gl_popup} onClick={() => setdeletePopup(false)}>
              Cancel
            </div>
            <div className={s.employee_gl_popup_del} onClick={() => deleteuserFunc()}>
              Delete
            </div>
          </div>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
      <Loder loading={isLoading} />
      <ImagePreview image={bannerImagePreview}/>
    </div>
  );
};

export default PujaBanners;
