import { Button, Card, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import Custombutton from "../../../Common/Custombutton";
import { notificationHandler } from "../../../utils/Notification";
import { updatePujaOrders } from "../../api/pujaHistory";
import Loder from "../../../Loder/Loder";
import s from "../Puja.module.css";

const EditPujaHistory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [chadawaUrl, setChadawaUrl] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [originalVideoUrl, setOriginalVideoUrl] = useState("");
  const [pujaStatus, setPujaStatus] = useState("");
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [video_message_sent, setVideoMessageSent] = useState(false);

  useEffect(() => {
    setChadawaUrl(location?.state?.data?.chadawa_url);
    setVideoUrl(location?.state?.data?.video_recording_url);
    setPujaStatus(location?.state?.data?.puja_status);
    setOriginalVideoUrl(location?.state?.data?.original_video_recording_url);
    setData(location?.state?.data);
    setVideoMessageSent(location?.state?.data?.video_message_sent);
  }, [location]);

  const submitHistory = async (trigger) => {
    setIsLoading(true);
    const data_payload = {
      chadawa_url: chadawaUrl,
      video_recording_url: videoUrl,
      puja_status: pujaStatus,
      trigger_message: trigger,
      original_video_recording_url: originalVideoUrl,
    };
    try {
      let res = await updatePujaOrders(data_payload, data.id);
      if (res.data.status) {
        navigate("/pujas-history");
        if (trigger) {
          notificationHandler({ type: res.data?.data?.trigger_status ? "success" : "danger", msg: res.data?.data?.trigger_message });
        }
        else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } else {
        notificationHandler({ type: "success", msg: res.data.message });
      }
      setIsLoading(false);
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <div className="">
        <Card className={s["admin_container"]}>
          <div className={s["back-button"]} onClick={() => navigate(-1)}>
            <BiArrowBack />
            Back
          </div>
          <h4>Puja Details</h4>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} margin={'15px 0'}>
            {/* Name */}
            <Grid item xs={6}>
              <div>
                <h6>Puja Name</h6>
                {data?.name}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <h6>Temple Name</h6>
                {data?.temple_name}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <h6>Puja Booking End Time</h6>
                {`${data.pooja_booking_end_time?.slice(
                  0,
                  10
                )} ${data?.pooja_booking_end_time
                  ?.split("T")[1]
                  ?.slice(0, 5)}`}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <h6>Actual Puja Time</h6>
                {`${data.actual_pooja_time?.slice(
                  0,
                  10
                )} ${data?.actual_pooja_time
                  ?.split("T")[1]
                  ?.slice(0, 5)}`}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <h6>MRP</h6>
                {data?.mrp}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <h6>Price</h6>
                {data?.price}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <h6>Occasion</h6>
                {data?.occasion}
              </div>
            </Grid>
          </Grid>

          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            {/* Video recording url */}
            <Grid item xs={12}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Video Recording Url (Shorter)</label>
                <div style={{ 'display': 'flex', 'gap': '10px' }}>
                  <textarea type="text" className="form-control" name="video_recording_url" value={videoUrl} onChange={(e) => setVideoUrl(e.target.value)} placeholder="Video Recording Url" />
                  {video_message_sent ? 
                  <Button disabled style={{ "border": "1px solid rgb(58, 62, 68)" }}>
                    Video Message Triggered
                  </Button>
                  :
                  <Button onClick={() => submitHistory(true)} style={{ "border": "1px solid #5b81ba" }}>
                    Trigger Video WA Message
                  </Button>
                  }
                </div>
              </div>
            </Grid>

            {/* Chadawa Url */}
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Chadawa Url</label>
                <textarea type="text" className="form-control" name="chadawa_url" value={chadawaUrl} onChange={(e) => setChadawaUrl(e.target.value)} placeholder="Chadawa Url" />
              </div>
            </Grid>

            {/* Original Video Recording Url */}
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Original Video Recording Url</label>
                <textarea type="text" className="form-control" name="original_video_recording_url" value={originalVideoUrl} onChange={(e) => setOriginalVideoUrl(e.target.value)} placeholder="Original Video Recording Url" />
              </div>
            </Grid>

            {/* Puja Status */}
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Puja Status</label>
                <div className="mr-2">
                  <div className="form-group">
                    <select className="form-control" value={pujaStatus} onChange={(e) => setPujaStatus(e.target.value)}>
                      <option value={"scheduled"}>Scheduled</option>
                      <option value={"completed"}>Completed</option>
                      {/* <option value={"cancelled"}>Cancelled</option> */}
                      <option value={"refunded"}>Refunded</option>
                    </select>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className={s["form-login-btn"]} style={{ 'width': '35%' }} onClick={() => submitHistory(false)}>
              <Custombutton>Submit</Custombutton>
            </div>
          </div>
        </Card>
      </div>
      <Loder loading={isLoading} />
    </>
  );
};

export default EditPujaHistory;
