import { Autocomplete, Card, Chip, Grid, MenuItem, Select, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { getMandirList } from "../api/mandir";
import { addMandirPuja, updateMandirPuja } from "../api/mandirPuja";
import { getPujaAddons } from "../api/pujaAddons";
import { getPackageList } from "../api/pujaPackage";
import s from "./Puja.module.css";
import { getBenefitsList } from "../api/pujaBenefits";

const AddMandirPuja = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [ packageList, setPackageList ] = useState([])
  const [ mandirList, setMandirList ] = useState([])
  const [ addonsList, setAddonsList ] = useState([]);
  const [ lang, setLang ] = useState("en"); 
  const [ name, setName ] = useState("");
  const [ shortName, setShortName ] = useState("");
  const [ shortNameCount, setShortNameCount ] = useState(0);
  const [ description, setDescription ] = useState("");
  const [ slug, setSlug ] = useState("");
  const [ mrp, setMrp ] = useState("")
  const [ price, setPrice ] = useState("")
  const [ pujaDate, setPujaDate ] = useState("");
  const [ endDate, setEndDate ] = useState("");
  const [ occasion, setOccasion ] = useState("");
  const [ participantCount, setParticipantCount ] = useState("");
  const [ aboutPuja, setAboutPuja ] = useState("");
  const [ files, setFiles ] = useState([]); 
  const [ aboutImage, setAboutImage ] = useState([])
  const [ templeId, setTempleId ] = useState(null)
  const [ inputValue, setInputValue ] = useState(""); 
  const [ packageId, setPackageId ] = useState([])
  const [ addonsId, setAddonsId ] = useState([])
  const [ benefits, setBenefits ] = useState([])
  const [ benefitsId, setBenefitsId ] = useState([])
  const [ status, setStatus ] = useState("");
  const [ id, setId ] = useState("")
  const [ pageCount, setpageCount ] = useState(1);
  const [ search, setsearch ] = useState("");
  const [ isLoading, setIsLoading ] = useState(false);
  const [chadawaURL, setChadawaURL] = useState("");

  useEffect(() => {
    fetchPackageList()
    fetchMandirList();
    fetchAddons()
    fetchBenefits()
  }, [pageCount, search]);

  const handleLangChange = (selectedLang) => {
    setLang(selectedLang);
  };

  async function fetchPackageList() {
    try {
      let { data } = await getPackageList();
      if (data.status) {
        setPackageList(data?.data.packages);
      } else {
        setIsLoading(false);
        console.log("status false!");
      }
    } catch (error) {
      console.log(error);
    }
    finally {
      setIsLoading(false);
    }
  }

  async function fetchMandirList() {
    try {
      let { data } = await getMandirList();
      if (data.status) {
        setMandirList(data?.data?.temples);
      } else {
        setIsLoading(false);
        console.log("status false!");
      }
    } catch (error) {
      console.log(error);
    }
    finally {
      setIsLoading(false);
    }
  }

  async function fetchAddons() {
    setIsLoading(true);
    try {
      let res = await getPujaAddons();
      if (res.data.status) {
        setAddonsList(res?.data?.data?.addons);
      } else {
        setIsLoading(false);
        console.log("status false!");
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchBenefits() {
    setIsLoading(true);
    try {
      let res = await getBenefitsList();
      if (res.data.status) {
        setBenefits(res?.data?.data.benefits);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        console.log("status false!");
      }
    } catch (error) {
      console.log(error);
    }
  }

  const pagetype = location.state.pagetype;

  const formatDateWithoutTimezone = (date) =>{
    if(!date) return "";
    let [date_ob, time_ob] = date?.split("T") || [];
    time_ob = time_ob?.slice(0, 8);
    return date_ob + "T" + time_ob;
  }

  useEffect(() => {
    setId(location?.state?.data?.id)
    setLang(location?.state?.data?.language)
    setName(location?.state?.data?.name || "");
    setDescription(location?.state?.data?.description || "");
    setStatus(location?.state?.data?.status)
    setSlug(location?.state?.data?.slug || "")
    setMrp(location?.state?.data?.mrp || "")
    setPrice(location?.state?.data?.price || "")
    setPujaDate(formatDateWithoutTimezone(location?.state?.data?.actual_pooja_time))
    setEndDate(formatDateWithoutTimezone(location?.state?.data?.pooja_booking_end_time))
    setOccasion(location?.state?.data?.occasion || "")
    setParticipantCount(location?.state?.data?.participation_count || "")
    setAboutPuja(location?.state?.data?.additional_details?.aboutPuja || "")
    setTempleId(location?.state?.data?.temple_id || null)
    setPackageId(location?.state?.data?.packages || []);
    setAddonsId(location?.state?.data?.addons || []);
    setBenefitsId(location?.state?.data?.benefit_ids || []);
    setChadawaURL(location?.state?.data?.chadawa_url || "");
    setShortName(location?.state?.data?.short_name || "");
    setShortNameCount(location?.state?.data?.short_name?.length || 0);
  }, [location]);

  const handleFileChange = (e) => {
    setFiles([...e.target.files])
  }

  const pujaFunc = async (entryType) => {
    if(shortNameCount > 27) {
      notificationHandler({ type: "danger", msg: "Short Name should be less than or equal to 27 characters" });
      return;
    }
    if (pagetype == "Add") {
      const fd = new FormData();
      {files && files.forEach(file => fd.append("productImage", file))}
      {aboutImage && aboutImage.forEach(file => fd.append("about_puja_image", file))}
      fd.append("language", lang);
      fd.append("name", name);
      fd.append("shortName", shortName);
      fd.append("description", description);
      fd.append("status", status);
      fd.append("slug", slug);
      fd.append("mrp", mrp);
      fd.append("price", price);
      fd.append("actualPoojaTime", new Date(pujaDate).toISOString());
      fd.append("scheduledTime", new Date(endDate).toISOString());
      fd.append("occasion", occasion);
      fd.append("participationCount", participantCount);
      fd.append("aboutPooja", aboutPuja);
      fd.append("templeId", templeId);
      fd.append("packages", packageId);
      fd.append("addons", addonsId);
      fd.append("benefit_ids", benefitsId);
      fd.append("chadawaURL", chadawaURL);
      try {
        let res = await addMandirPuja(fd);
        if (res.data.status) {
          navigate("/mandir-puja");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
    if (pagetype == "Edit") {
      const fd = new FormData();
      {files && files.forEach(file => fd.append("productImage", file))}
      {aboutImage && aboutImage.forEach(file => fd.append("about_puja_image", file))}
      fd.append("language", lang);
      fd.append("name", name);
      fd.append("shortName", shortName);
      fd.append("description", description);
      fd.append("status", status);
      fd.append("slug", slug);
      fd.append("mrp", mrp);
      fd.append("price", price);
      fd.append("actualPoojaTime", new Date(pujaDate).toISOString());
      fd.append("scheduledTime", new Date(endDate).toISOString());
      fd.append("occasion", occasion);
      fd.append("participationCount", participantCount);
      fd.append("aboutPooja", aboutPuja);
      fd.append("templeId", templeId);
      fd.append("packages", packageId);
      fd.append("addons", addonsId);
      fd.append("benefit_ids", benefitsId);
      fd.append("entry_type", entryType);
      fd.append("chadawaURL", chadawaURL);
      try {
        let res = await updateMandirPuja(fd, id);
        if (res.data.status) {
          navigate("/mandir-puja");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
  };

  // useEffect(() => {
  //   if (mandirList?.length > 0 && !templeId) {
  //     setTempleId(mandirList[0]?.id); 
  //   }
  // }, [mandirList, templeId]);

  // const memoizedMandirList = useMemo(() => mandirList, [mandirList]);

  return (
    <>
      <div className="">
        <Card className={s["admin_container"]}>
          <div className={s["back-button"]} onClick={() => navigate(-1)}>
            <BiArrowBack />
            Back
          </div>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12}>
          <div className="form-group" style={{display: "flex", flexDirection: "column"}}>
              <label htmlFor="english-lang" style={{display: "flex", gap: "8px"}}>
                <input
                  className="form-check-input"
                  name="lang"
                  type="radio"
                  value="eng"
                  checked={lang === "en"}
                  onChange={() => handleLangChange("en")}
                  id="english-lang"
                />
                English
              </label>
              <label htmlFor="hindi-lang" style={{display: "flex", gap: "8px"}}>
                <input
                  className="form-check-input"
                  name="lang"
                  type="radio"
                  value="hi"
                  checked={lang === "hi"}
                  onChange={() => handleLangChange("hi")}
                  id="hindi-lang"
                />
                Hindi
              </label>
            </div>
            </Grid>

             {/* Name */}
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Title</label>
                <textarea type="text" className="form-control" name="name" value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" />
              </div>
            </Grid>

            {/* Short Name */}
            <Grid item xs={6}>
              <div className="form-group">
                <div style={{'display': 'flex', 'justifyContent': 'space-between'}}>
                  <label htmlFor="exampleInputEmail1">Short Title</label>
                  <div style={shortNameCount > 27 ? {'color':'red'}: {}}>
                    {shortNameCount > 27 ? 'Character limit exceeded - ' : ''}
                    {shortNameCount}/27
                  </div>
                </div>
                <textarea type="text" className="form-control" name="name" value={shortName} onChange={(e) => {setShortName(e.target.value); setShortNameCount(e.target.value?.length)}} placeholder="Short Name of the Puja" />
              </div>
            </Grid>

             {/* Description */}
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Description</label>
                <textarea type="text" className="form-control" name="description" value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Description" />
              </div>
            </Grid>

              {/* Slug */}
               <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Slug</label>
                <input type="text" className="form-control" name="slug" value={slug} onChange={(e) => setSlug(e.target.value)} placeholder="Slug" />
              </div>
            </Grid>

             {/* MRP */}
             <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">MRP</label>
                <input type="text" className="form-control" name="mrp" value={mrp} onChange={(e) => setMrp(e.target.value)} placeholder="MRP" />
              </div>
            </Grid>


             {/* Price */}
             <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Price</label>
                <input type="text" className="form-control" name="price" value={price} onChange={(e) => setPrice(e.target.value)} placeholder="Price" />
              </div>
            </Grid>

            {/* Image */}
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Select Image</label>
                <div className="mr-2">
                <input type="file" multiple className="form-control" name="img" placeholder="" accept="image/*" onChange={handleFileChange} />
                </div>
              </div>
            </Grid>

             {/* Puja Date */}
             <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Scheduled Date</label>
                <div className="mr-2">
                  <input
                    type="datetime-local"
                    className="form-control"
                    value={pujaDate}
                    onChange={(e) => setPujaDate(e.target.value)}
                  />
                </div>
              </div>
            </Grid>


            {/* End Date */}
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Booking End Date</label>
                <div className="mr-2">
                  <input
                    type="datetime-local"
                    className="form-control"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>
              </div>
            </Grid>

             {/* Occasion */}
             <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Occasion</label>
                <input type="text" className="form-control" name="occasion" value={occasion} onChange={(e) => setOccasion(e.target.value)} placeholder="Occasion" />
              </div>
            </Grid>


             {/* Participant Count */}
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Participant Count</label>
                <input type="text" className="form-control" name="participantCount" value={participantCount} onChange={(e) => setParticipantCount(e.target.value)} placeholder="Count" />
              </div>
            </Grid>

            {/* Chadawa URL */}
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Chadawa URL</label>
                <input type="text" className="form-control" name="chadawaURL" value={chadawaURL} onChange={(e) => setChadawaURL(e.target.value)} placeholder="Chadawa URL" />
              </div>
            </Grid>


            {/* About Image */}
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">About Image</label>
                <div className="mr-2">
                <input type="file" multiple className="form-control" name="img" placeholder="" accept="image/*" onChange={(e)=>setAboutImage([...e.target.files])} />
                </div>
              </div>
            </Grid>

            {/* About Puja */}
              <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">About Puja</label>
                <textarea type="text" className="form-control" name="aboutPuja" value={aboutPuja} onChange={(e) => setAboutPuja(e.target.value)} placeholder="About Puja" />
              </div>
            </Grid>

            {/* Mandir List */}
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Select Mandir</label>
                <div className="mr-2">
                <div className="form-group">
                  <Autocomplete
                    id="mandir-select"
                    options={mandirList}
                    getOptionLabel={(option) => option?.name || ""}
                    value={templeId ? mandirList.find((mandir) => mandir.id === templeId) : null}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setTempleId(newValue.id); 
                      } else {
                        setTempleId(null); 
                      }
                    }}
                    inputValue={inputValue} 
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue); 
                      if (newInputValue === "") {
                        setTempleId(null); 
                      }
                    }}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                 </div>
                </div>
              </div>
            </Grid>
            

            {/* Packages */}
              <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Packages</label>
                <div className="mr-2">
                  <div className="form-group">
                  <Autocomplete
                   multiple
                   id="packages-autocomplete"
                   options={packageList}
                   getOptionLabel={(option) => {
                     return typeof option === 'string' ? option : option?.name;
                   }}
                   value={ packageId ? packageId?.map(id => packageList?.find(pack => pack?.id === id) || id) : []}
                   onChange={(event, newValue) => {
                     const updatePackage = newValue.map((item) => {
                       if (typeof item === 'string') {
                         return { id: item, name: item };
                       }
                       return item; 
                     });
                     setPackageId(updatePackage?.map((item) => item.id));
                       }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            key={typeof option === 'string' ? option : option?.id}
                            variant="outlined"
                            label={typeof option === 'string' ? option : option?.name}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined"  />
                      )}
                    />             
                  </div>
                </div>
              </div>
            </Grid>

            
             {/* Addons List */}
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Addons</label>
                <div className="mr-2">
                  <div className="form-group">
                  <Autocomplete
                   multiple
                   id="addons-autocomplete"
                   options={addonsList}
                   getOptionLabel={(option) => {
                     return typeof option === 'string' ? option : option?.name;
                   }}
                   value={addonsId?.map(id => addonsList.find(addon => addon.id === id) || id)}
                   onChange={(event, newValue) => {
                     const updatedAddons = newValue.map((item) => {
                       if (typeof item === 'string') {
                         return { id: item, name: item };
                       }
                       return item; 
                     });
                     setAddonsId(updatedAddons?.map((item) => item.id));
                       }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            key={typeof option === 'string' ? option : option?.id}
                            variant="outlined"
                            label={typeof option === 'string' ? option : option?.name}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined"  />
                      )}
                    />             
                  </div>
                </div>
              </div>
            </Grid>

             {/* Benefits */}
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Benefits</label>
                <div className="mr-2">
                <div className="form-group">
                  <Autocomplete
                   multiple
                   id="addons-autocomplete"
                   options={benefits}
                   getOptionLabel={(option) => {
                     return typeof option === 'string' ? option : option?.title;
                   }}
                   value={benefitsId?.map(id => benefits.find(benefit => benefit.id === id) || id)}
                   onChange={(event, newValue) => {
                     const updatesBenefits = newValue.map((item) => {
                       if (typeof item === 'string') {
                         return { id: item, title: item };
                       }
                       return item; 
                     });
                     setBenefitsId(updatesBenefits?.map((item) => item.id));
                       }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            key={typeof option === 'string' ? option : option?.id}
                            variant="outlined"
                            label={typeof option === 'string' ? option : option?.title}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined"  />
                      )}
                    />             
                  </div>
                </div>
              </div>
            </Grid>

            {/* Status */}
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Status</label>
                <div className="mr-2">
                  <div className="form-group">
                  <Select
        labelId="status-label"
        id="status"
        value={status || ""}
        onChange={(e) => setStatus(e.target.value)} 
        fullWidth
      >
        <MenuItem value="draft">Draft</MenuItem>
        <MenuItem value="published">Published</MenuItem>
        {/* <MenuItem value="deleted">Deleted</MenuItem> */}
      </Select>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>

            {
              pagetype === "Add" ? 
            <div className={s["form-login-btn"]} onClick={() => pujaFunc()}>
              <Custombutton>Submit</Custombutton>
            </div>
            :
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <div className={s["form-login-btn"]} onClick={() => pujaFunc("update")}>
                  <Custombutton>Update Puja</Custombutton>
                </div>
                <div className={s["form-login-btn"]} onClick={() => pujaFunc("new")}>
                  <Custombutton>New Puja</Custombutton>
                </div>
            </Grid>
            }
        </Card>
      </div>
    </>
  );
};

export default AddMandirPuja;

