import { Card, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { addBanner_api, updateBanner_api } from "../api/banner";
import s from "../Banner/banner.module.css";

const AddPujaBanners = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pagetype = location.state.pagetype;

  const [title, setTitle] = useState("");
  const [file, setfile] = useState(null);

  const [startDate, setstartDate] = useState()
  const [endDate, setendDate] = useState("")
  const [ slug, setSlug ] = useState("")
  const [priority, setpriority] = useState(1)
  const [status, setstatus] = useState()

  useEffect(() => {
    if (location.state.data) {
      console.log(location.state);
      setTitle(location?.state?.data?.title);
      setSlug(location?.state?.data?.slug);
      setpriority(location?.state?.data?.priority);
      setstatus(location?.state?.data?.status);
      setstartDate(location?.state?.data?.start_date?.slice(0, -2));
      setendDate(location?.state?.data?.end_date?.slice(0, -2));
     
    }
  }, [location]);


  const Category_function = async () => {
    if (pagetype == "Add") {
      let img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = async () => {
        if (
          1.7 < Number(img.width / img.height).toFixed(2) &&
          1.8 > Number(img.width / img.height).toFixed(2)
        ) {
          if (new Date(startDate) > new Date(endDate)) {
            return notificationHandler({
              type: "danger",
              msg: "Start Date Should Be Less Than End Date!",
            });
          }

          const fd = new FormData();
          fd.append("title", title);
          fd.append("banner_image", file);
          fd.append("startDate", new Date(startDate).toISOString());
          fd.append("endDate", new Date(endDate).toISOString());
          fd.append("bannerType", "mandir_puja")  
          fd.append("slug", slug);
          fd.append("priority", priority);

          let res = await addBanner_api(fd);
          if (res.data.status) {
            navigate("/puja-banners");
            notificationHandler({ type: "success", msg: res.data.message });
          } else {
            notificationHandler({ type: "success", msg: res.data.message });
            console.log("Some Error!");
          }
        } else {
          notificationHandler({
            type: "danger",
            msg: "Recommended Ratio 25:14 (i.e. 1000px * 560px)!",
          });
        }
      };
    }
    if (pagetype == "Edit") {

      const fd = new FormData();
      fd.append("id", location?.state?.data?.id);
      fd.append("title", title);
      if (file) fd.append("banner_image", file);
      fd.append("startDate", new Date(startDate).toISOString());
      fd.append("endDate", new Date(endDate).toISOString());
      fd.append("bannerType", "mandir_puja")   
      fd.append("slug", slug)
      fd.append("priority", priority);
      fd.append("status", status);
      

      let res = await updateBanner_api(fd);
      if (res.data.status) {
        navigate("/puja-banners");
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        notificationHandler({ type: "success", msg: res.data.message });
        console.log("Some Error!");
      }
    }
  };

  return (
    <>
      <div className="">
        <Card className={s["admin_container"]}>
          <div className={s["title"]} onClick={() => navigate(-1)}>
            <BiArrowBack />
            Back
          </div>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Title</label>
                <div className="mr-2">
                  <input
                    type="text"
                    className="form-control"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Priority</label>
                <div className="mr-2">
                  <div className="form-group">
                    <select
                      className="form-control"
                      value={priority}
                      onChange={(e) => setpriority(e.target.value)}
                    >
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                      <option value={4}>4</option>
                    </select>
                  </div>
                </div>
              </div>
            </Grid>
          
            <Grid item xs={6}>
                <div className="form-group">
                  <label for="exampleInputEmail1">Select Image</label>
                  <div className="mr-2">
                    <input
                      type="file"
                      className="form-control"
                      accept="image/*"
                      onChange={(e) => setfile(e.target.files[0])}
                    />
                  </div>
                </div>
            </Grid>

            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Slug</label>
                <div className="mr-2">
                <input
                    type="text"
                    className="form-control"
                    value={slug}
                    onChange={(e) => setSlug(e.target.value)}
                    />
                </div>
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Start Date</label>
                <div className="mr-2">
                  <input
                    type="datetime-local"
                    className="form-control"
                    value={startDate}
                    onChange={(e) => setstartDate(e.target.value)}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">End Date</label>
                <div className="mr-2">
                  <input
                    type="datetime-local"
                    className="form-control"
                    value={endDate}
                    onChange={(e) => setendDate(e.target.value)}
                  />
                </div>
              </div>
            </Grid>

            
            {pagetype == "Edit" && (
              <Grid item xs={6}>
                <div className="form-group">
                  <label for="exampleInputEmail1">Status</label>
                  <div className="mr-2">
                    <div className="form-group">
                      <select
                        className="form-control"
                        value={status}
                        onChange={(e) => setstatus(e.target.value)}
                      >
                        <option value={true}>Active</option>
                        <option value={false}>InActive</option>
                      </select>
                    </div>
                  </div>
                </div>
              </Grid>
            )}
          </Grid>

          <div
            className={s["form-login-btn"]}
            onClick={() => Category_function()}
          >
            <Custombutton>Submit</Custombutton>
          </div>
        </Card>
      </div>
    </>
  );
};

export default AddPujaBanners;
