import React, { useState, useEffect, useCallback, useRef } from "react";
import s from "../Astrologer/astrologer.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { useLocation } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import { addAstrologerCredit_api } from "../api/astrologer";

const AstrologerAddWallet = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [paymentType, setpaymentType] = useState("credit");
    const [amount, setamount] = useState("");
    const [description, setDescription] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const debounceRef = useRef(null);

    const pagetype = location?.state?.pagetype;

    // Debounced function to prevent multiple submissions
    const debouncedCategoryFunction = useCallback(() => {
        if (isSubmitting) return;
        setIsSubmitting(true);

        let tempData = {
            astroId: location.state?.data?.data?.id,
            amount: Number(amount),
            transType: paymentType,
        };

        if (description) {
            tempData.title = description;
        }

        if (pagetype === "Add") {
            addAstrologerCredit_api(tempData)
                .then((res) => {
                    if (res.data.status) {
                        navigate("/astrologer-list");
                        notificationHandler({ type: "success", msg: res.data.message });
                    } else {
                        notificationHandler({ type: "warning", msg: res.data.message });
                    }
                })
                .catch((error) => {
                    notificationHandler({ type: "danger", msg: error.message });
                    console.log(error);
                })
                .finally(() => {
                    setTimeout(() => setIsSubmitting(false), 2000); // Delay re-enabling button
                });
        }
    }, [amount, paymentType, description, pagetype, isSubmitting, navigate, location]);

    // Function to trigger debounce
    const handleSubmit = () => {
        if (debounceRef.current) clearTimeout(debounceRef.current);
        debounceRef.current = setTimeout(() => {
            debouncedCategoryFunction();
        }, 500); // 500ms debounce delay
    };

    return (
        <>
            <div className="">
                <Card className={s["admin_container"]}>
                    <div className={s["title"]} onClick={() => navigate(-1)}>
                        <BiArrowBack />
                        Back
                    </div>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label>Payment Type</label>
                                <select className="form-control" value={paymentType} onChange={(e) => setpaymentType(e.target.value)}>
                                    <option value="credit">Deposit</option>
                                    <option value="debit">Withdraw</option>
                                    <option value="refund">Refund</option>
                                </select>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label>Amount</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    value={amount}
                                    onChange={(e) => setamount(e.target.value)}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label>Description</label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    value={description}
                                    onChange={(e) => {
                                        if (e.target.value.length <= 140) {
                                            setDescription(e.target.value);
                                        }
                                    }}
                                />
                                <div>{description.length}/140</div>
                            </div>
                        </Grid>
                    </Grid>

                    <div className={s["form-login-btn"]} onClick={handleSubmit}>
                        <Custombutton disabled={isSubmitting}>
                            {isSubmitting ? "Processing..." : "Submit"}
                        </Custombutton>
                    </div>
                </Card>
            </div>
        </>
    );
};

export default AstrologerAddWallet;
