import axios from "axios";
import Cookies from "js-cookie";
import { getBaseUrl } from "../../utils";

const chadhava = getBaseUrl() + "admin/mandirChadhava";
const updateChadhava = getBaseUrl() + "admin/mandirChadhava";
const addChadhava = getBaseUrl() + "admin/mandirChadhava";
// const deleteChadhava = getBaseUrl() + "admin/mandirChadhava";

export const getMandirChadhavaList = async (data) => {
    let config = {
      method: "get",
      url: chadhava + `?page=${data.page}&limit=${data.limit}&search=${data.search}`,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    };
  
    try {
      let res = await axios(config);
      return res;
    } catch (error) {
      console.log(error);
      return error.response;
    }
  };

  export const updateMandirChadhava = async (data, id) => {
    let config = {
      method: "patch",
      url: updateChadhava + `/${id}`,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      data: data,
    };
  
    try {
      let res = await axios(config);
      return res;
    } catch (error) {
      console.log(error);
      return error.response;
    }
  };

  export const addMandirChadhava = async (data) => {
    let config = {
      method: "post",
      url: addChadhava,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      data: data,
    };
    
    try {
      let res = await axios(config);
      return res;
    } catch (error) {
      console.log(error);
      return error.response;
    }
  };
  
  // export const deleteMandirChadhava = async (data) => {
  //   let config = {
  //     method: "delete",
  //     url: deleteChadhava + `/${data.id}`,
  //     headers: { Authorization: `Bearer ${Cookies.get("token")}` },
  //   };
  
  //   try {
  //     let res = await axios(config);
  //     return res;
  //   } catch (error) {
  //     console.log(error);
  //     return error.response;
  //   }
  // };