import { Dialog, DialogActions, DialogContent, DialogTitle, Pagination } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { IoMdAdd } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Loder from "../../../Loder/Loder";
import { notificationHandler } from "../../../utils/Notification";
import DataNotFound from "../../ErrorPage/DataNotFound";
import { deleteMandirChadhava, getMandirChadhavaList } from "../../api/mandirChadhava";
import s from "../Puja.module.css";


const MandirChadhavaList = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [chadhavaList, setChadhavaList] = useState([])
  const [pageLength, setPageLength] = useState();
  const [search, setsearch] = useState("");
  const [deletePopup, setDeletePopup] = useState(false);
  const [currentGroup, setCurrentGroup] = useState({});
  const [deleteId, setDeleteId] = useState("");
  const [pageCount, setpageCount] = useState(1);


  useEffect(() => {
    fetchMandirChadhavaList();
  }, [pageCount, search]);

  async function fetchMandirChadhavaList() {
    try {
      const temp = {
        page: pageCount,
        limit: 8,
        search: search.trim(),
      };
      let { data } = await getMandirChadhavaList(temp);
      if (data.status) {
        setChadhavaList(data?.data.products);
        setPageLength(data?.data?.totalPages);
      } else {
        setIsLoading(false);
        console.log("status false!");
      }
    } catch (error) {
      console.log(error);
    }
    finally {
      setIsLoading(false);
    }
  }


  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "var(--clr-theme)",
      color: theme.palette.common.white,
      fontWeight: "bold",
      borderRight: "1px solid #fff",
      overflow: "hidden",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: "none",
      borderLeft: "2px solid #00000011",
      "&:last-child": {
        borderRight: "2px solid #00000011",
      },
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    borderBottom: "2px solid #00000011",
  }));

  // async function deleteChadhavaFunc() {
  //   setIsLoading(true);
  //   console.log("deleteId", deleteId)
  //   let temp = {
  //     id: deleteId,
  //   };
  //   try {
  //     let { data } = await deleteMandirChadhava(temp);
  //     if (data.status) {
  //       setIsLoading(false);
  //       setDeletePopup(false);
  //       fetchMandirChadhavaList();
  //       notificationHandler({ type: "success", msg: data.message });
  //     } else {
  //       setIsLoading(false);
  //       notificationHandler({ type: "danger", msg: data.message });
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     notificationHandler({ type: "danger", msg: error.message });
  //   }
  // }

  return (
    <div className="">
      <div className={s["beat_heading"]}>
        <div className={s["user-list-heading"]}>
          <div style={{
            display: "flex",
            gap: "10px"
          }} className="beat_left ">
            <div
              className={s["title"]}
              onClick={() =>
                navigate("/add-mandir-chadhava", {
                  state: {
                    pagetype: "Add",
                  },
                })
              }
            >
              <IoMdAdd /> Add Mandir Chadhava
            </div>
          </div>
          <div className={s["user-list-search"]}>
            <div className={s["search-box"]}>
              <span style={{ paddingRight: "0.5rem" }}>
                <BiSearch size={23} />
              </span>
              <input
                type="text"
                spellCheck="false"
                onChange={(e) => {
                  setpageCount(1);
                  setsearch(e.target.value);
                }}
                placeholder="Search by title..."
              />
            </div>

          </div>
        </div>

      </div>


      <div className="beat_table">
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Image</StyledTableCell>
              <StyledTableCell align="center">Title</StyledTableCell>
              <StyledTableCell align="center">Description</StyledTableCell>
              <StyledTableCell align="center">Booking End Date</StyledTableCell>
              <StyledTableCell align="center">Scheduled Date</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {chadhavaList?.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell>
                  {row.product_img ? <img style={{ height: "2rem", width: "3rem" }} src={row.product_img} alt="temple" /> : null}
                </StyledTableCell>

                <StyledTableCell align="center">{row.name} </StyledTableCell>
                <StyledTableCell align="center">{row.description}</StyledTableCell>
                <StyledTableCell align="center">{`${row.pooja_booking_end_time?.slice(
                  0,
                  10
                )} ${row.pooja_booking_end_time
                  ?.split("T")[1]
                  ?.slice(0, 5)}`}</StyledTableCell>
                <StyledTableCell align="center">{`${row.actual_pooja_time?.slice(
                  0,
                  10
                )} ${row.actual_pooja_time
                  ?.split("T")[1]
                  ?.slice(0, 5)}`}</StyledTableCell>
                <StyledTableCell align="center">
                  <div
                    style={{ cursor: "pointer" }}
                    className={`${row.status === 'published' ? s.published : row.status === 'draft' ? s.draft : s.deleted}`}
                  >
                    {row.status.toUpperCase()}
                  </div>
                </StyledTableCell>

                <StyledTableCell align="center">
                  <div className="astrologer-action-btn">
                    <span
                      onClick={() => {
                        navigate("/add-mandir-chadhava", {
                          state: {
                            pagetype: "Edit",
                            data: row,
                          },
                        })
                      }}
                    >
                      Edit
                    </span>
                    {/* <span
                      onClick={() => {
                        setDeletePopup(true);
                        setCurrentGroup(row);
                        setDeleteId(row.id);
                      }}
                    >
                      Delete
                    </span> */}
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        {chadhavaList?.length <= 0 && <DataNotFound />}
        {chadhavaList?.length > 0 && (
          <div className={s["pagination"]}>
            <Pagination count={pageLength} size="large" style={{ color: "#D21903" }} onChange={(e, value) => { setpageCount(value); localStorage.setItem("astrologerIndex", value) }} page={pageCount} />
          </div>
        )}
      </div>

      {/* delete astrologer popup */}
      {/* <Dialog open={deletePopup} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth="true" onClose={() => setDeletePopup(false)}>
      <DialogTitle className={s.dialog_title}>
        <div>Do you want to delete {currentGroup.name}?</div>
      </DialogTitle>
      <DialogContent className={s.cardpopup_content}>
        <div style={{ display: "flex", gap: "1rem" }}>
          <div className={s.employee_gl_popup} onClick={() => setDeletePopup(false)}>
            Cancel
          </div>
          <div className={s.employee_gl_popup_del} onClick={() => deleteChadhavaFunc()}>
            Delete
          </div>
        </div>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog> */}
      <Loder loading={isLoading} />
    </div >
  )
}

export default MandirChadhavaList;