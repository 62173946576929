import React from "react";
import AddMandirChadhava from "../components/Puja/MandirChadhava/AddMandirChadhava";

const AddCategoryPage = () => {
  return (
    <>
      <AddMandirChadhava />
    </>
  );
};

export default AddCategoryPage;
